/* global Highcharts */


// #############################################################################
// Wizzard: Formularvalidierung für Gebäudeinformationen

(function($) {
  'use strict';

  var pluginName = 'buildingResultValidation';

  function Plugin($element) {
    this.$element = $element;
    this.$el = $($element);
    this.$inputs = $('[required]', this.$el);
    this.init();
  }

  $.extend(Plugin.prototype, {
    init: function() {
      var _this = this;

      // var $input = _this.$inputs.eq(_this.$inputs.index(this));
      // var is_valid = _this.checkInputValidity($input);

      _this.$inputs.on('blur input', function() {
        // var $input = _this.$inputs.eq(_this.$inputs.index(this));
        // var is_valid = _this.checkInputValidity($input);
      });
    },
    checkInputValidity: function($input) {
      // var _this = this;
      //var $label = $('[for="' + $input.attr('id') + '"]');
      //var $error = $('[data-error]', $label);

      if ($input[0].checkValidity()) {
        $input.removeClass('is-invalid');
        //$error.removeClass('d-inline');
        //$error.addClass('d-none');
        return true;
      }

      $input.addClass('is-invalid');
      //$error.addClass('d-inline');
      //$error.removeClass('d-none');

      return false;
    },
  });

  $.fn[pluginName] = function(options) {
    return this.each(function() {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
      }
    });
  };
})(jQuery);

$('#saved_examples').buildingResultValidation();


// #############################################################################
// Lädt die Diagramme sobald diese im Sichtbaren bereich sind.

(function($) {

  // init checkbox
  var $input_checked = $('#check_entries' + ' :checked');
  $input_checked.each(function(index) {
    var $checkbox = $input_checked.eq(index);
    $checkbox.prop('checked', false);
  });

  function update_checkbox() {
    var $data_tab = $('[data-toggle="tab"]').filter('.active');
    var href = $data_tab.attr('href');
    var $tab_content = $(href);
    var $diagram = $('[data-chart]', $tab_content);

    var arr_entries = [];
    var $input_checked = $('.check_entries_' + $diagram.attr('id') + ' :checked');
    $input_checked.each(function(index) {
      var $checkbox = $input_checked.eq(index);
      var $entry_id = $checkbox.attr('id');
      arr_entries.push($entry_id.split('_')[1]);
    });

    $.ajax({
      type: 'POST',
      url: $diagram.attr('data-chart'),
      data: {
        'entries': JSON.stringify(arr_entries)
      },
      success: function(data) {
        $diagram.attr('data-chart-loaded', '1');
        Highcharts.chart($diagram.attr('id'), build_chartdata(data, $diagram.attr('id')));

      }
    });
  }

  $('[data-checkbox]').on('click', function() {
    update_checkbox();
  });

  $('[data-toggle="tab"]').on('click', function(event) {
    var $tab = $(event.target);
    var href = $tab.attr('href');
    var $tab_content = $(href);
    var $diagram = $('[data-chart]', $tab_content);

    var arr_entries = [];
    var $input_checked = $('.check_entries_' + $diagram.attr('id') + ' :checked');
    $input_checked.each(function(index) {
      var $checkbox = $input_checked.eq(index);
      var $entry_id = $checkbox.attr('id');
      arr_entries.push($entry_id.split('_')[1]);
    });

    if ($diagram.length) {
      $.ajax({
        type: 'POST',
        url: $diagram.attr('data-chart'),
        data: {
          'entries': JSON.stringify(arr_entries)
        },
        success: function(data) {
          $diagram.attr('data-chart-loaded', '1');
          Highcharts.chart($diagram.attr('id'), build_chartdata(data, $diagram.attr('id')));

        }
      });
    }

  });
  /*Diagram initialisieren*/
  var $diagram = $('[data-load-first]');
  if ($diagram.length) {
    jQuery.each($diagram, function() {
      var _this = $(this);
      $.getJSON($(this).attr('data-chart'), function(data) {
        _this.attr('data-chart-loaded', '1');
        Highcharts.chart(_this.attr('id'), build_chartdata(data, _this.attr('data-chart-type')));
      });
    });
  }
})(jQuery);

function build_chartdata(data, type) {
  if (type == 'usage_demand_chart') {

    return {
      chart: {
        height: '400',
        type: 'column'
      },
      title: {
        text: data.title
      },
      xAxis: {
        categories: data.categories
      },
      yAxis: {
        min: 0,
        title: {
          text: data.yAxis.title
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
          }
        }
      },
      legend: {
        align: 'right',
        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
        borderColor: '#CCC',
        verticalAlign: 'top',
        layout: 'vertical',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        x: 0,
        y: 100
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
          }
        }
      },
      series: data.series
    }
  }
  else if (type === 'output_chart') {
    return {
      chart: {
        height: '200',
        type: 'bar'
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        reversed: true,
        layout: 'vertical',
        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
        borderColor: '#CCC',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        x: 0,
        y: 100
      },
      /*tooltip: {
          formatter: function() {
              return '<b>' + this.x +
                  '</b> ist <b>' + this.point.name + '</b>';
          }
      },*/
      plotOptions: {
        series: {
          colorByPoint: true
        }
      },
      title: {
        text: data.title
      },
      xAxis: {
        categories: data.categories,

      },
      yAxis: {
        visible: false
      },
      credits: {
        enabled: false
      },
      series: [{
        data: data.series,
        showInLegend: false
      }]
    }
  }
  else {
    return {
      chart: {
        height: '400',
        type: 'column'
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
        borderColor: '#CCC',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        x: 0,
        y: 100
      },
      title: {
        text: data.title
      },
      xAxis: {
        categories: data.categories,

      },
      yAxis: {
        title: {
          text: data.yAxis.title
        },
      },
      credits: {
        enabled: false
      },
      series: data.series
    }
  }
}

